import { Component, inject, input, output, ElementRef, ViewChild, computed, signal, Renderer2, HostListener, effect } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { LinkComponent } from '@teamfoster/sdk/button';
import { IconComponent } from '@teamfoster/sdk/icon';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { Subject, Subscription } from 'rxjs';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ImageComponent } from '@teamfoster/sdk/image';
import { HoverDelayDirective } from '../../directives/hover-delay.directive';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  standalone: true,
  styleUrls: ['./topbar.component.scss'],
  imports: [
    RouterModule,
    LinkComponent,
    JsonPipe,
    IconComponent,
    InViewModule,
    DictionaryNgrxModule,
    fromMenu.OfMenuTypePipe,
    ImageComponent,
    HoverDelayDirective,
  ],
})
export class TopbarComponent {
  private router = inject(Router);
  private document = inject(DOCUMENT);
  private resizeSubject = new Subject<Event>();

  @ViewChild('topbar') topbar?: ElementRef;
  @ViewChild('topUpperbar') upperBar?: ElementRef;

  collapseOnScroll = input<boolean>(true);
  loading = input<boolean>(false);
  menuActive = input<boolean>(false);
  logoClass = input<string>('');
  menu = input<fromMenu.BaseMenuItem[]>([]);
  upperMenu = input<fromMenu.BaseMenuItem[]>([]);

  fixedNav$ = signal(false);
  submenuActive$ = signal(false);
  activeButton$ = signal<string>('');

  toggleOverlay = output<void>();
  closeOverlay = output<void>();
  openOverlay = output<void>();

  private navigationStart$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));

  // routerEffect$ = effect(() => {
  //   const navigationStart = this.navigationStart$();

  //   if (this.menuActive()) {
  //     this.closeOverlay.emit();
  //   }
  // });

  menuItems$ = computed(() => this.menu().filter(item => !item.type || item.type === 'MenuItem') as fromMenu.MenuItem[]);
  menuHiglight$ = computed(() => this.menuItems$().filter(item => item.featured));
  menuDefault$ = computed(() => this.menuItems$().filter(item => !item.featured));

  activeSubnavClass$ = effect(() => {
    const active = this.submenuActive$();

    if (active) {
      this.document.documentElement.classList.add('menu--submenu-active');
    } else {
      this.document.documentElement.classList.remove('menu--submenu-active');
    }
  });

  constructor() {}

  inview($event: boolean) {
    if (this.collapseOnScroll()) {
      this.fixedNav$.set($event);
    }
  }

  openMobileMenu() {
    this.toggleOverlay.emit();

    if (this.submenuActive$()) {
      this.submenuActive$.set(false);
      this.activeButton$.set('');
    }
  }

  toggleClass(page: fromMenu.MenuItem) {
    const currentActiveButton = this.activeButton$();
    // const button = event.target as Element;
    // // this.renderer.toggleClass(button, 'is--active');

    // button.classList.toggle('is--active');
    if (currentActiveButton === page.guid) {
      this.activeButton$.set('');
      this.submenuActive$.set(false);
      // this.closeOverlayMenu();
    } else {
      this.activeButton$.set(page.guid);
      this.submenuActive$.set(true);
    }
  }

  closeOverlayMenu() {
    this.activeButton$.set('');
    this.submenuActive$.set(false);
    this.closeOverlay.emit();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition > 130) {
      this.fixedNav$.set(true);
    } else {
      this.fixedNav$.set(false);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeSubject.next(event);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closeOverlayMenu();
  }
}
