<div class="topbar" #topbar [class.set--fixed]="fixedNav$()" [class.menu--active]="menuActive()">
  <div class="container">
    <nav class="topbar__main d-flex align-items-center">
      <div class="topbar__logo mb-0">
        <span class="d-none">{{ 'meta-titel' | fromDictionary }}</span>
        <a [routerLink]="'/'" (click)="closeOverlayMenu()">
          <img width="782" height="203" src="/assets/gfx/logo-wuestman-liggend.svg" [alt]="'meta-titel' | fromDictionary" />
        </a>
      </div>

      <div class="topbar-mainnav d-lg-flex align-items-center" [class.set--active]="menuActive()">
        <ul class="topbar-mainnav__list menu--default d-lg-flex ms-lg-6">
          @for (page of menuDefault$(); track page) {
            <li class="topbar-mainnav__list__item" [class.has--subnav]="page.menuItems.length" #toggleButton>
              @if (page.menuItems.length > 0) {
                <button
                  class="button button-link button--level-1 toggle--subnav-button button--dark"
                  [class.set--active]="activeButton$() === page.guid"
                  [hoverDelay]="200"
                  (hoverDelayComplete)="toggleClass(page)"
                >
                  <span class="toggle--subnav-button__label" [routerLinkActive]="'text-primary'" [routerLink]="page.routerLink">
                    {{ page.title }}
                  </span>

                  <span class="ms-auto me-0"><fstr-icon anchor="icon-chevron-down" [viewbox]="'0 0 24 24'"></fstr-icon></span>
                </button>
              } @else {
                <fstr-link
                  cssClasses="button button-link  button--level-1 button--dark"
                  [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                  [showText]="false"
                  (click)="closeOverlayMenu()"
                >
                  <span>
                    {{ page.title }}
                  </span>
                </fstr-link>
              }
              @if (page.menuItems.length) {
                <nav class="topbar-subnav">
                  <div class="container">
                    <div class="topbar-subnav__wrapper d-lg-flex">
                      <div class="topbar-subnav__list col-lg-15">
                        @if (page.label) {
                          <h3 class="text-primary h6 mb-3 mb-lg-4">{{ page.label }}</h3>
                        }

                        <ul class="list-unstyled d-flex flex-column flex-md-row flex-wrap gap-lg-4">
                          @for (subitem of page.menuItems | ofMenuType: 'MenuItem'; track subitem) {
                            <li class="topbar-subnav__list__item">
                              <fstr-link
                                cssClasses="button button-link  button--dark"
                                [button]="{ text: subitem.title, url: subitem.url || '', routerLink: subitem.routerLink }"
                                [routerLinkActive]="'is--active'"
                                [routerLinkActiveOptions]="{ exact: subitem.url === '/' }"
                                [showText]="false"
                                (click)="closeOverlayMenu()"
                              >
                                <span class="gap-2 align-items-center d-flex">
                                  {{ subitem.title }}
                                  @if (!subitem.routerLink) {
                                    <fstr-icon anchor="line-icon-external-link"></fstr-icon>
                                  }
                                </span>

                                <em class="text-muted d-none d-lg-block fs-body-xs">{{ subitem.label }}</em>
                              </fstr-link>
                            </li>
                          }
                        </ul>
                      </div>

                      @if (page.menuItems | ofMenuType: 'CTA'; as ctaItems) {
                        @if (ctaItems.length) {
                          <aside class="topbar-subnav__aside bg-primary-subtle col-lg-9 mt-5 mt-md-0 d-flex flex-column gap-5">
                            @for (subitem of ctaItems; track subitem) {
                              <article>
                                <strong class="text-primary h6">{{ subitem.label }}</strong>
                                <strong class="h5 d-block">{{ subitem.title }}</strong>
                                <p class="text-muted fs-body-xs pe-xl-7">
                                  {{ subitem.text }}
                                </p>
                                <footer class="mt-auto mt-5 mt-md-0 d-flex flex-wrap gap-2">
                                  <fstr-link
                                    [button]="{
                                      url: subitem.primaryButton.url,
                                      text: subitem.primaryButton.label,
                                      routerLink: subitem.primaryButton.routerLink,
                                    }"
                                    [queryParams]="subitem.primaryButton.queryParams"
                                    [cssClasses]="'button button--accent button--xs'"
                                    [routerLinkActive]="''"
                                  >
                                    <fstr-icon [viewbox]="'0 0 24 24'" anchor="line-icon-arrow-right"></fstr-icon>
                                  </fstr-link>

                                  @if (subitem.secondaryButton?.url) {
                                    <fstr-link
                                      [button]="{
                                        url: subitem.secondaryButton!.url!,
                                        text: subitem.secondaryButton!.label!,
                                        routerLink: subitem.secondaryButton!.routerLink,
                                      }"
                                      [cssClasses]="'button button--light  button--xs'"
                                      [routerLinkActive]="''"
                                    >
                                      @if (!subitem.secondaryButton!.routerLink) {
                                        <fstr-icon anchor="line-icon-arrow-right"></fstr-icon>
                                      } @else {
                                        <fstr-icon anchor="line-icon-arrow-right"></fstr-icon>
                                      }
                                    </fstr-link>
                                  }
                                </footer>
                              </article>
                            }
                          </aside>
                        }
                      }
                      <span class="subnav--overlay" (mouseenter)="closeOverlayMenu()"></span>
                    </div>
                  </div>
                </nav>
              }
            </li>
          }
        </ul>
        <ul class="topbar-mainnav__list menu--highligted ms-auto d-flex gap-3 gap-lg-1">
          @for (page of menuHiglight$(); track page; let i = $index) {
            <li class="topbar-mainnav__list__item">
              <fstr-link
                [cssClasses]="i === 0 ? 'button button--accent button--level-1' : 'button button--primary button--level-1'"
                [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                [routerLinkActive]="'is--active'"
                [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                [showText]="false"
                (click)="closeOverlayMenu()"
              >
                <span>{{ page.title }}</span>
              </fstr-link>
            </li>
          }
        </ul>
      </div>
      <button
        class="button button--accent text-decoration-none ms-auto d-lg-none"
        aria-label="Toon uitgebreid menu"
        [class.is--active]="menuActive()"
        (click)="openMobileMenu()"
      >
        @if (menuActive()) {
          <span>Sluiten</span>
        } @else {
          <span>Menu</span>
        }
        <span class="hamburger" [class.is--active]="menuActive()">
          <span class="hamburger__lines"></span>
        </span>
      </button>
    </nav>
  </div>
</div>
