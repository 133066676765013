<footer class="footer">
  <app-team-cta></app-team-cta>

  <main class="bg-gray-100 py-7 text-secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-5 mb-md-0">
          <div class="footer__logo mb-0">
            <span class="d-none">{{ 'meta-titel' | fromDictionary }}</span>
            <a [routerLink]="'/'">
              <img width="782" height="203" src="/assets/gfx/logo-wuestman-liggend.svg" [alt]="'meta-titel' | fromDictionary" />
            </a>
          </div>
          <p class="mt-4" [innerHTML]="'footer-adres' | fromDictionary"></p>
          <ul class="footer-adress-links">
            <li>
              <a class="button button--info button-link" [href]="'tel:' + ('footer-telefoonnummer' | fromDictionary)">
                <fstr-icon anchor="line-icon-phone"></fstr-icon>
                <span>{{ 'footer-telefoonnummer' | fromDictionary }}</span>
              </a>
            </li>
            <li>
              <a class="button button--info button-link" [href]="'mailto:' + ('footer-emailadres' | fromDictionary)">
                <fstr-icon anchor="icon-mail"></fstr-icon>
                <span>{{ 'footer-emailadres' | fromDictionary }}</span>
              </a>
            </li>
          </ul>

          <ul class="d-flex gap-2 flex-wrap footer-list__awards mt-3">
            <li><img [src]="'footer-hofleverancier-logo' | fromDictionary" alt="Hofleverancier" /></li>

            <!--<li>
              <fstr-link [button]="{ text: '', url: ('footer-diamant-link' | fromDictionary) }" [showText]="false">
                <img [src]="'footer-diamant-logo' | fromDictionary" alt="De diamant van Midden Nederland" />
              </fstr-link>
            </li>-->
          </ul>
        </div>
        <div class="col-md-12 mb-5 mb-md-0">
          <div class="d-flex flex-wrap footer__menus">
            @for (item of menu(); track item) {
              <nav class="footer__menus__menu">
                <h3 class="h5">{{ item.label || item.title }}</h3>
                <ul class="mt-4 footer-menu-links">
                  @for (subitem of item.menuItems | ofMenuType: 'MenuItem'; track subitem) {
                    <li>
                      <!-- <a class="button button--info button-link" [routerLink]="'/'">Linkje</a> -->
                      <fstr-link
                        cssClasses="button button--info button-link"
                        [button]="{ text: subitem.title, url: subitem.url || '', routerLink: subitem.routerLink }"
                        [routerLinkActive]="'is--active'"
                        [routerLinkActiveOptions]="{ exact: subitem.url === '/' }"
                        [showText]="true"
                      ></fstr-link>
                    </li>
                  }
                </ul>
              </nav>
            }
          </div>
        </div>

        <nav class="col-md-6">
          <h3 class="h5">{{ 'footer-volg-ons-titel' | fromDictionary }}</h3>

          <app-social-channel-buttons class="my-4 d-block" [channels]="socials()"></app-social-channel-buttons>

          <a class="button button--light" [href]="'footer-nieuwsbrief-link' | fromDictionary" target="_blank">
            <span>{{ 'footer-nieuwsbrief-label' | fromDictionary }}</span>
            <fstr-icon anchor="line-icon-chevron-right"></fstr-icon>
          </a>
        </nav>
      </div>
    </div>
  </main>
  <div class="container">
    <ul class="footer__nav-list py-4 d-flex flex-wrap fs-body-xs text-dark">
      @for (page of bottomMenu(); track page) {
        <li class="footer__nav-list__item">
          <fstr-link
            cssClasses="button button-link button--dark"
            [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
            [routerLinkActive]="'is--active'"
            [routerLinkActiveOptions]="{ exact: page.url === '/' }"
            [showText]="true"
          ></fstr-link>
        </li>
      }

      <li class="footer__nav-list__item">
        <button (click)="openCookie()" class="button button--dark button-link">{{ 'cookie-settings' | fromDictionary }}</button>
      </li>
      <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
    </ul>
  </div>

  <app-json-ld [json]="schema()"></app-json-ld>
</footer>
