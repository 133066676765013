import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[hoverDelay]',
  standalone: true,
})
export class HoverDelayDirective {
  private timeout: any;

  @Input() hoverDelay: number = 500; // Standaardwaarde van 500 ms
  @Output() hoverDelayComplete = new EventEmitter<void>();

  @HostBinding('class.hovering') isHovering = false;

  @HostListener('mouseenter') onMouseEnter() {
    this.isHovering = true;
    this.timeout = setTimeout(() => {
      this.hoverDelayComplete.emit();
    }, this.hoverDelay);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHovering = false;
    clearTimeout(this.timeout);
  }
}
