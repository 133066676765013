<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()" [@fadeAnimation]="outlet.activatedRouteData">
    @if (loaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
      ></app-topbar>
      <!--[menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"-->
    }

    <div class="app__router">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </div>
  @if (loaded$()) {
    <app-footer
      [menu]="('footer-menu' | fromMenu)?.error ? [] : ('footer-menu' | fromMenu)"
      [bottomMenu]="('footer-bottom-menu' | fromMenu)?.error ? [] : ('footer-bottom-menu' | fromMenu)"
      [socials]="socials$()"
      [domain]="domainName"
    ></app-footer>
  }

  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>

  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
</div>

@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}
